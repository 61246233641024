import Navigation from './Navigation'

(function () {
    class DropdownSheet extends Navigation {
        constructor(container, type) {
            super(container)
            this.type = type
            this.appendClassName = 'booking-menu'
            this.triggers = document.querySelectorAll("[href='#booking-table-menu']")
            this.$childElems = this.$el.querySelectorAll('.dropdown-sheet__container > *')
            this.tweening = false
            this.options.triggerActiveText = 'close'
            this.options.triggerInActiveText = 'Book a table'
            this.triggerTextUpdater()
            this.init()
        }

        createAnim() {
            this.createMainTl()
            this.tl.add(this.openBottomSheet())
        }

        triggerTextUpdater() {
            this.options.triggerInActiveText = window.innerWidth > 768 ? 'Book a table' : 'Book'
            this.options.triggerActiveText = window.innerWidth > 768 ? 'Close' : 'Close'
        }

        resizeFunc() {
            this.triggerTextUpdater()
            this.dropDownHeightUpdater()
            return true
        }
    } 

    document.querySelector('#booking-table-sheet') && new DropdownSheet(document.querySelector('#booking-table-sheet'), 'dropdown')
})()