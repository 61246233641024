import './Main-nav-sheet'
import './Booking-sheet'
import './Booking-table-sheet'
import './Hotellist-sheet'
import './Lang-sheet'


//js for the fixed book button
$(".fixed-book-icon-close").click(() => {
    $(".fixed-book").hide();
})