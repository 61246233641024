// Styles
import './scss/main.scss'

// Polyfils
import "@/scripts/pollyfills.js"

// Svg Sprites
import "@/scripts/icons"

// Commons global initializations, config apply, etc
import "@/scripts/common"

// Main scripts
// Headers
import "@/scripts/header"
// Navigation
import "@/scripts/navigation"
// Animations and Lazyloading
import "@/scripts/revealanimation"
import "@/scripts/lazyload"
// Ripple
import "@/scripts/ripple"
// Dropdown
import "@/scripts/dropdown"
// Daterange triggers
import "@/scripts/daterange"
// Search
import "@/scripts/search"
// Calc widget
import "@/scripts/calc-input"
// Material input
import "@/scripts/material-input"
// Bookng from
import(/* webpackChunkName: "booking-forms" */ '@/scripts/bookingform')
// Video
import(/* webpackChunkName: "blog" */ '@/scripts/video')
// Maps
import(/* webpackChunkName: "maps" */ '@/scripts/maps')
// ReadMore
import(/* webpackChunkName: "readmore" */ '@/scripts/readmore')
// Modal
import(/* webpackChunkName: "modal" */ '@/scripts/modal')
// Form
import(/* webpackChunkName: "form" */ '@/scripts/forms')
// Carousels
import(/* webpackChunkName: "carousels" */ '@/scripts/carousels')
// Accordion
import(/* webpackChunkName: "accordion" */ '@/scripts/accordion')
// Blog
import(/* webpackChunkName: "blog" */ '@/scripts/blog')
// gallery
import(/* webpackChunkName: "gallery" */ '@/scripts/gallery')
// Scroll
import(/* webpackChunkName: "scroll" */ '@/scripts/scroll')
// UIfilters
import(/* webpackChunkName: "ui-filters" */ '@/scripts/ui-filter')
// UIPagination
import(/* webpackChunkName: "ui-pagination" */ '@/scripts/ui-pagination')
// ArticleGroup
import(/* webpackChunkName: "article-group" */ '@/scripts/article-group')
// Social
import(/* webpackChunkName: "social" */ '@/scripts/social')
// Weather
import(/* webpackChunkName: "weather" */ '@/scripts/weather')
