import utilities from "./common/utilities"
import settings from "./common/settings"



(function (window) {
    'use strict'

    const FOCUS_HEADER_CLASS = "highlight-header"
    const Header = document.querySelector('#main-header')
    const HeaderContainer = Header && Header.querySelector('.main-header__inner>.main-header__container')
    const headerOffset = 126
    const headerMobileOffset = 120
    let isMobile = window.innerWidth < settings.breakpoints.mobile
    
    const headerInteractions = function () {
        const focusHeaderClassName = FOCUS_HEADER_CLASS
        const addHighlight = function () {
            !utilities.isMobile() && document.body.classList.add(focusHeaderClassName)
        }

        const removeHighlight = function () {
            !utilities.isMobile() && document.body.classList.remove(focusHeaderClassName)
        }

        HeaderContainer.addEventListener('mouseenter', addHighlight)
        HeaderContainer.addEventListener('mouseleave', removeHighlight)
    }

    const headerContentOffset = function () {
        const headerHeight = !isMobile ? headerOffset : headerMobileOffset

        Header.style.paddingTop = `${headerHeight}px`
    }

    const reisizeFunc = utilities.debounce(function() {
        isMobile = window.innerWidth < settings.breakpoints.mobile
        headerContentOffset()
    }, 250)

    
    if(Header) {
        headerInteractions()
        if(!Header.classList.contains('main-header--transparent')) {
            headerContentOffset()
            window.addEventListener('resize', reisizeFunc)
        }

        // For animation end and zindex issue
        setTimeout(() => Header.classList.add('initiated'), 1800)
        
    }
        
    
})(window)
