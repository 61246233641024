import settings from '@/scripts/common/settings'
import gsap from 'gsap/gsap-core'
import Navigation from './Navigation'

(function () {
    class MainNav extends Navigation {
        constructor(container, type) {
            super(container)
            this.type = type
            this.hijackScroller = true
            this.appendClassName = !this.isMobile ? 'primary-menu' : 'primary-mobile-menu'
            this.triggers = document.querySelectorAll("[href='#menu'], .side-sheet__close")
            this.$nav = document.querySelector('#main-nav')
            this.$navItems = this.$el.querySelectorAll('.main-nav__item')
            this.$staggerItems = this.$el.querySelectorAll('.main-nav__item, .top-links-mobile, .main-nav__list > .sub-nav__item, .main-nav__item > .sub-nav__list > .sub-nav__item')
            this.$dropDownTriggers = this.$el.querySelectorAll('[class*="__item--dropdown"]')
            this.isMobile = window.innerWidth < settings.breakpoints.mobile
            this.$hoverImgItems = document.querySelectorAll('#main-nav [data-hover-img]')
            this.$coverBanner = null

            this.activeCoverImage = 0



            this.tweening = false
            this.navInit()
        }

        navInit() {
            if (this.$hoverImgItems.length && !this.isMobile) this.createHoverCover()
            this.init()
            this.addDropDownListener()
            if (this.$hoverImgItems.length && !this.isMobile) this.addHoverImgListeners()
        }

        createAnim() {
            this.killMainTl()
            this.createMainTl()
            this.tl.add(this.openSideNav())
            // this.$navItems && this.tl.from(this.$navItems, {
            //     autoAlpha: 0,
            //     stagger: 0.1,
            //     x: -100,
            //     duration: 0.8,
            //     ease: this.options.ease
            // }, 0)

            this.$staggerItems && this.tl.from(this.$staggerItems, {
                autoAlpha: 0,
                stagger: 0.01,
                x: -100,
                duration: 0.6,
                ease: this.options.ease
            }, 0)

            if(this.$coverBanner)
                this.tl.to(this.$coverBanner, {
                    autoAlpha: 1,
                    display: 'block',
                    duration: 0.6,
                    ease: this.options.ease
                }, 0.2)
        }

        onClosing() {
            this.accordionReset()
        }

        accordionReset() {
            this.$dropDownTriggers.forEach(trigger => {
                const panel = trigger.querySelector('.sub-nav__list')
                trigger.classList.remove('sub-nav__item--open')
                this.accordionOpen(false, panel)
            })
        }

        accordionReveal(trigger) {
            const panel = trigger.querySelector('.sub-nav__list')
            const panelOpen = trigger.classList.contains('sub-nav__item--open')

            this.accordionReset()

            if (!panelOpen) {
                trigger.classList.add('sub-nav__item--open')
                this.accordionOpen(true, panel)
            } else {
                trigger.classList.remove('sub-nav__item--open')
                this.accordionOpen(false, panel)
            }
        }

        accordionOpen(open, ele) {
            const panel = ele
            gsap.to(panel, {
                duration: 0.3,
                height: open ? 'auto' : 0
            })
        }

        // Hover Cover art
        createHoverCover() {
            const $coverBanner = document.createElement('div')
            $coverBanner.classList.add('navigation-cover-sheet')

            this.$hoverImgItems.forEach(($item, i) => {
                const banner = document.createElement('div')
                banner.classList.add('banner', 'contain-bg')
                banner.setAttribute('data-image-id', i)
                banner.innerHTML = `<div class="background"><div class="bg-img lazyload" data-image-array='[{"screen": 0, "src": "${$item.dataset.hoverImg}"}]'></div></div>`
                gsap.set(banner, {
                    autoAlpha: 0,
                    display: 'none'
                })
                $coverBanner.appendChild(banner)
            })

            gsap.set($coverBanner, {
                autoAlpha: 0,
                display: 'none'
            })

            this.$coverBanner = $coverBanner
            
            document.body.appendChild(this.$coverBanner)
            this.updateCover()
        }

        deleteCover() {
            if(this.$coverBanner) {
                document.body.removeChild(this.$coverBanner)
                this.activeCoverImage = 0
                this.$coverBanner = null
            }
        }

        clearAllCover() {
            const $covers = this.$coverBanner.querySelectorAll('.banner')
            gsap.to($covers, {
                duration: 0.8,
                autoAlpha: 0,
                display: 'none',
                ease: 'none'
            })
        }

        updateCover() {
            this.clearAllCover()
            const $cover = this.$coverBanner.querySelectorAll('.banner')[this.activeCoverImage]
            gsap.to($cover, {
                duration: 0.8,
                autoAlpha: 1,
                display: 'block',
                ease: 'none'
            })
        }

        coverLinkOver(e, i) {
            this.activeCoverImage = i
            this.updateCover()
        }

        // Responsive Listeners
        resizeFunc() {
            if(this.isMobile) {
                this.deleteCover()
            } else {
                if(!this.$coverBanner){
                    this.createHoverCover()
                }
            }
            return true
        }


        // Listeners
        addDropDownListener() {
            this.$dropDownTriggers.forEach(trigger => trigger.addEventListener("click", () => this.accordionReveal(trigger)))
        }

        addHoverImgListeners() {
            this.$hoverImgItems.forEach(($trigger, i) => {
                $trigger.addEventListener('mouseover', (e) => this.coverLinkOver(e, i))
            })
        }
    }

    document.querySelector('#side-nav') && new MainNav(document.querySelector('#side-nav'), 'side-nav')
})()