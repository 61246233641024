export default class TimePicker {
    constructor(container) {
        this.$el = container
        this.$input = this.$el.querySelector('input[type="hidden"]')
        this.startTime = this.$input.dataset.startTime && moment().utc().set({hour:parseInt(this.$input.dataset.startTime), minute:0}) || moment().utc().set({hour:11,minute:0})
        this.endTime = this.$input.dataset.endTime && moment().utc().set({hour:parseInt(this.$input.dataset.endTime), minute:0}) || moment().utc().set({hour:23,minute:0})
        this.reservedSlots = this.$input.dataset.reservedSlots && JSON.parse(this.$input.dataset.reservedSlots) || []
        this.timeStops = this.$input.dataset.slots ?  JSON.parse(this.$input.dataset.slots) : []
        this.selectedTime = this.$input.value || ''
        this.events = []
        this.init()

    }

    init() {
        this.$el.value = this.selectedTime
        if(this.timeStops.length === 0) this.makeStops()
        this.makeHtml()
        this.eventCreator()
        this.$el.getTimePicker = () => this
    }

    _isReserved(val) {
        return this.reservedSlots.findIndex(slot => slot === val) !== -1
    }

    makeStops() {
        while(this.startTime <= this.endTime){
            const time = new moment(this.startTime).format('HH:mm')
            const item = {
                time,
                reserved: this._isReserved(time)
            }
            this.timeStops.push(item)
            this.startTime.add(30, 'minutes')
        }
    }

    makeHtml () {
        if(this.$el.querySelector('.timepicker__table')) this.$el.querySelector('.timepicker__table').remove()
        const table = document.createElement('div')
        table.classList.add('timepicker__table')
        
        this.timeStops.forEach(stop => {
            const tableCell = document.createElement('div')
            tableCell.classList.add('timepicker__table-cell')
            if(stop.reserved) tableCell.classList.add('timepicker__table-cell--reserved')
            tableCell.innerHTML = `<span class="timepicker__time">${stop.time}</span>`
            tableCell.addEventListener('click', () => this.cellClick(tableCell, stop))
            table.appendChild(tableCell)
        })

        this.$el.appendChild(table)
    }

    eventCreator() {
        this.events['timeSelected'] = new CustomEvent('timeSelected', {
            bubbles: true,
            detail: {
                getValue: ()  => {
                    time: this.selectedTime
                }
            }
        })
    }

    // Methods
    updateTimeList(slots) {
        this.timeStops = []
        this.selectedTime = ''
        
        slots.forEach(slot => {
            const item = {
                time: slot.time,
                reserved: slot.reserved
            }
            this.timeStops.push(item)
        })
        
        this.updateValue()
        this.makeHtml()
    }

    cellClick(cell, val) {
        if(val.reserved || (this.selectedTime === val.time)) return
        this.selectedTime = val.time
        this.toggleActive(cell)
        this.updateValue()
        this.$el.dispatchEvent(this.events['timeSelected'])
    }

    updateValue() {
        this.$input.value = this.selectedTime
        this.$el.value = this.selectedTime
    }

    toggleActive(cell) {
        const cells = this.$el.querySelectorAll('.timepicker__table-cell')
        cells.forEach(cell => cell.classList.remove('timepicker__table-cell--selected'))
        cell.classList.add('timepicker__table-cell--selected')
    }
}