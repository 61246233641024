import TimePicker from '../timepicker/Timepicker'
import {settings} from '@/scripts/common/settings'
import {DATE_TIME_LOCAL,
MONTH_NAMES,
DAYS_SHORT
} from '@/scripts/config/locale.config'
(function ($, window) {
    'use strict'

    // Booking date

    const pickers = $('.booking__daterange')

    pickers.each((i, picker) => {
        const input = $(picker).find('>input[type="hidden"]')
        const singleDatePicker = input.data("dateSingle") || false
        const totalSpan = input.data("maxSpan") || 120;
        const today = new Date()
        const tomorrow = new Date(today)
        const minEndDate = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        minEndDate.setDate(minEndDate.getDate() + 2)
        const startDate = input.data("startDate") || input.val() || tomorrow
        const endDate = input.data("endDate") || minEndDate
        const dob = input.data("dob") || false

        var _defaultOptions = {
            "autoApply": true
        }

        var options = null

        options = Object.assign(_defaultOptions, {
            "locale": {
                ...DATE_TIME_LOCAL,
                "monthNames": MONTH_NAMES,
                "daysOfWeek": DAYS_SHORT
            },
            "maxSpan": false,
            "linkedCalendars": false,
            "parentEl": picker,
            "startDate": startDate,
            "endDate": endDate,
            "minDate": startDate,
            "singleDatePicker": singleDatePicker
        })

        if(totalSpan) {
            options['maxSpan'] = {
                "days": parseInt(totalSpan)
            }
        }

        if (dob) {
            options = Object.assign(_defaultOptions, {
                singleDatePicker: true,
                showDropdowns: true,
                minDate: '01/01/1901',
                minYear: 1901,
                maxYear: parseInt(moment().format('YYYY'), 10),
            })
        }

        input.daterangepicker(options, _bookingDateCallBack)

        input.data('daterangepicker').show()
        picker.startDate = null
        picker.endDate = null
        
        if(input[0].value) _updateLabel(input[0], `${input[0].value}`) // Update with start dat on init if value input present

        input.on('change', function () {
            const autoApplyLabel = $(this).data("autoApplyLabel") || false
            let val = $(this).val()
            _updateDateVal(picker, val)
            if(autoApplyLabel) _updateLabel(this, val)
        })

        picker.updateDateOptions = function(options) {
            const pickerInput = $(picker).find('>input[type="hidden"]')
            
            Object.assign($(pickerInput).data('daterangepicker'),
                {
                    ...options
                }
            )

            $(pickerInput).data('daterangepicker')

            return picker
        }

        picker._setStartDate = function(startDate) {
            const pickerInput = $(picker).find('>input[type="hidden"]')
            $(pickerInput).data('daterangepicker').setStartDate(startDate)
            $(pickerInput).data('daterangepicker').updateView()
            return picker
        }

        picker._setEndDate = function(endDate) {
            const pickerInput = $(picker).find('>input[type="hidden"]')
            $(pickerInput).data('daterangepicker').setEndDate(endDate)
            $(pickerInput).data('daterangepicker').updateView()
            return picker
        }

        picker._setMinDate = function(minDate) {
            const pickerInput = $(picker).find('>input[type="hidden"]')
            $(pickerInput).data('daterangepicker').setMinDate(minDate)
            $(pickerInput).data('daterangepicker').updateView()
            return picker
        }
    })


    function _bookingDateCallBack(start, end, label) {
        //console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
    }

    function _updateDateVal(ele, val) {
        $(ele)[0].startDate = null
        $(ele)[0].endDate = null
        let dates = val.split(' - ')
        const selectedStartDate = dates[0] ? moment(new Date(dates[0])).format(settings.dateDisplayFormat) : null
        const selectedEndDate = dates[1] ? moment(new Date(dates[1])).format(settings.dateDisplayFormat) : null
        
        if (selectedStartDate) $(ele)[0].startDate = selectedStartDate
        if (selectedEndDate) $(ele)[0].endDate = selectedEndDate
        
        //selectedStartDate && selectedEndDate && $(BookingInput).trigger('updateDate', [ selectedStartDate, selectedEndDate])
        if (selectedStartDate || selectedEndDate) $(ele)[0].dispatchEvent(new CustomEvent('dateUpdated', { bubbles: true, detail: { startDate: selectedStartDate, endDate: selectedEndDate } }))
    }

    function _updateLabel(input, val) {
        const $label = document.querySelector(input.dataset.labelLink)
        let dates = val.split(' - ')
        const selectedStartDate = dates[0] ? moment(new Date(dates[0])).format(settings.dateDisplayFormat) : null
        const selectedEndDate = dates[1] ? moment(new Date(dates[1])).format(settings.dateDisplayFormat) : null

        if($label) {
            $label.innerHTML = !selectedEndDate ? `${selectedStartDate}` : `${selectedStartDate} - ${selectedEndDate}`
        }
    }


    // TimePickers
    const timepickers = $('.timepicker')
    timepickers.each((i, picker) => {
        new TimePicker(picker)

        picker.addEventListener('timeSelected', function() {
            const $label = document.querySelector(this.querySelector('input').dataset.labelLink)
            const placeHolder = $label.textContent
            if($label) {
                $label.innerHTML = this.value ? `${this.value}` : `${placeHolder}`
            }
        })
    })

    




})(jQuery, window)