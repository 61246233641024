import { gsap } from "gsap";
import RevealAnimation from "../RevealAnimation.js"
class CommonTransition extends RevealAnimation {
    constructor(container, animation) {
        super(container)
        this.animation = animation || this.$el.dataset.animation
        this.init()
    }

    init() {
        this.switchAnimations()
        this.addScrollController()
    }

    switchAnimations() {
        switch (this.animation) {
            case 'stagger-from-bottom':
                this.tween = this.staggerFromBottom()
                break
            case 'from-bottom':
                this.tween = this.fromBottom()
                break
            case 'fade-in':
                this.tween = this.fadeIn()
                break
            case 'fade-in-stagger':
                this.tween = this.fadeInStagger()
                break
            default:
                this.tween = null
        }
    }

    // Animations

    staggerFromBottom() {
        const els = this.$el.children
        //gsap.set(els, { autoAlpha: 0 })
        return gsap.from(els, {
            autoAlpha: 0,
            y: 50,
            duration: this.options.defaultDuration,
            stagger: this.options.defaultStagger,
            ease: this.options.defaultRevealEase
        })
    }

    fromBottom() {
        return gsap.from(this.$el, {
            autoAlpha: 0,
            y: 50,
            duration: this.options.defaultDuration,
            ease: this.options.defaultRevealEase
        })
    }

    fadeInStagger() {
        const els = this.$el.children
        return gsap.from(els, {
            autoAlpha: 0,
            duration: this.options.defaultDuration,
            ease: this.options.defaultRevealEase,
            stagger: this.options.defaultStagger
        })
    }

    fadeIn() {
        return gsap.from(this.$el, {
            autoAlpha: 0,
            duration: this.options.defaultDuration,
            ease: this.options.defaultRevealEase
        })
    }

    // Callback defining
    onCompleteCallBack() {
        const els = this.$el.children
        if (els.length) gsap.set(els, { clearProps: "transform" })
    }
}

(function () {
    window.addEventListener('load', () => {
        setTimeout(() => {
            let revealAnimationElements = document.querySelectorAll('.reveal-animate')
            revealAnimationElements.forEach((ele) => {
                new CommonTransition(ele)
            })
        }, 500)
    })
})()
