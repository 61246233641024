const CLASS_PRE = 'calc'
export default class CalcWidget {
    constructor(container) {
        this.$el = container
        this.$input = this.$el.querySelector(`.${CLASS_PRE}__input`)
        this.$incBtn = this.$el.querySelector(`.${CLASS_PRE}__action--increment`)
        this.$decBtn = this.$el.querySelector(`.${CLASS_PRE}__action--decrement`)
        this.labelFormat = this.$el.dataset.labelFormat || '$1'
        this.labelLink = this.$el.dataset.labelLink || ''
        this.autoApplyLabel = !!this.$el.dataset.autoApplyLabel || false
        this.value = parseInt(this.$input.value)
        this.incCount = 1
        this.min = Math.round(parseInt(this.$el.dataset.min)) || 0
        this.max = Math.round(parseInt(this.$el.dataset.max)) || 100

        this.init()
    }

    init() {
        this.$el.value = this.value
        this.$el.update = (opts) => this.updateOptions(opts)
        this.$el.setValue = (val) => this.setValue(val)
        this.addListeners()
    }

    decrement() {
        this.value = this.value > this.min ? this.value - this.incCount : this.value
        this.updateInput()
    }

    increment() {
        this.value = this.value < this.max ? this.value + this.incCount : this.value
        this.updateInput()
    }

    onInputChange() {
        const newVal = parseInt(this.$input.value)
        this.value = newVal >= this.min && newVal <= this.max ? newVal : this.value
        this.updateInput()
    }

    formatedString() {
        let formatedString = ''
        
        if (this.labelFormat) {
            let format = this.labelFormat
            format = this.value > 1 ? `${format}s` : format
            formatedString = this.addNumberSeperator().replace(/^([\d-,]*)$/g, format)
        } else {
            formatedString = this.addNumberSeperator()
        }
        return formatedString
    }

    addNumberSeperator() {
        return Math.round(this.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    isNum() {
        let regex = /^[0-9]+$/
        return this.$input.value.match(regex) ? true : false
    }

    updateInput() {
        this.$input.value = this.value
        this.$el.value = this.value
        this.$el.dispatchEvent(new CustomEvent('valueUpdated', { bubbles: true, detail: { value: () => this.value } }))
        if(this.autoApplyLabel) this.updateLabel()
    }

    updateLabel() {
        const $label = document.querySelector(this.labelLink)
        if($label) $label.innerHTML = this.formatedString()
    }

    updateOptions({min, max}) {
        if(min !== undefined) this.min = min
        if(max !== undefined) this.max = max
    }

    setValue(val) {
        this.$input.value = val
        if ("createEvent" in document) {
            var evt = document.createEvent("HTMLEvents")
            evt.initEvent("change", false, true)
            this.$input.dispatchEvent(evt)
        } else
            this.$input.fireEvent("onchange")
    }

    addListeners() {
        this.$incBtn.addEventListener("click", () => this.increment())
        this.$decBtn.addEventListener("click", () => this.decrement())
        this.$input.addEventListener("change", () => this.onInputChange())
    }
}